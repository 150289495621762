@use '../../styleguide/colors.scss';

.buttonContainer {
  align-items: center;
  border: none;
  border-radius: 4px;
  color: colors.$white;
  cursor: pointer;
  display: flex;
  font-family: 'TT Prosto Sans Condensed', sans-serif;
  min-height: 48px;
  justify-content: center;
  width: 100%;
}

.iconLeft svg {
  margin-right: 8px;
}

.iconRight svg {
  margin-left: 8px;
}

.boxed svg {
  margin: 0;
}

.small {
  height: 36px;
}

.boxed {
  width: 48px;
}

.small.boxed {
  width: 36px;
}

.primary {
  background-color: colors.$primary-color;

  &:focus,
  &:hover {
    background-color: colors.$primary-color-dark;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.secondary {
  background-color: colors.$secondary-color;

  &:focus,
  &:hover {
    background-color: colors.$secondary-color-dark;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.white {
  background-color: colors.$white;
  color: colors.$primary-color;
}

.white:hover {
  background-color: colors.$primary-color-dark;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
}

.primary.inverted {
  background-color: transparent;
  border: 2px solid colors.$primary-color;
  color: colors.$primary-color;
}

.primary.inverted:hover {
  border: 2px solid colors.$primary-color-dark;
  color: colors.$primary-color-dark;
}

.primary.trivial {
  background-color: transparent;
  color: colors.$primary-color;
}

.primary.trivial:hover {
  color: colors.$primary-color-dark;
  box-shadow: none;
  text-decoration: underline;
}

.secondary.trivial {
  background-color: transparent;
  color: colors.$secondary-color;
}

.secondary.trivial:hover {
  color: colors.$secondary-color-dark;
  box-shadow: none;
  text-decoration: underline;
}

.secondary.inverted {
  background-color: transparent;
  border: 2px solid colors.$secondary-color;
  color: colors.$secondary-color;
}

.secondary.inverted:hover {
  border: 2px solid colors.$secondary-color-dark;
  color: colors.$secondary-color-dark;
}

.white.inverted {
  background-color: transparent;
  border: 2px solid colors.$white;
  color: colors.$white;
}

.white.inverted:hover {
  border: 2px solid colors.$primary-color-dark;
  color: colors.$primary-color-dark;
}

.disabled {
  background-color: colors.$grey;
  color: colors.$secondary-text;
  cursor: default;
}

.disabled:hover {
  background-color: colors.$grey;
  box-shadow: none;
  color: colors.$secondary-text;
}

.disabled.inverted {
  background-color: transparent;
  border: 2px solid colors.$grey;
  color: colors.$grey;
}

.disabled.inverted:hover {
  background-color: transparent;
  border: 2px solid colors.$grey;
  color: colors.$grey;
}

.disabled.trivial {
  background-color: transparent;
  color: colors.$grey;
}

.disabled.trivial:hover {
  background-color: transparent;
  color: colors.$grey;
  text-decoration: none;
}
