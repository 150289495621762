@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';
@use '../../styleguide/mixins.scss';

.root,
.rootWithSliderControls {
  display: grid;
  grid-template-columns: auto max-content;
  margin-bottom: vars.$gutter;
  align-items: center;
}

.title {
  color: colors.$dark-text;
  @include mixins.sectionTitle();

  span {
    color: colors.$primary-color;
  }
}

.arrow {
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.sliderControls {
  display: none;

  @media screen and (min-width: vars.$extra-large) {
    display: grid;
    gap: 5px;
    grid-auto-flow: column;
  }
}

.seeAll {
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    fill: var(--light-blue);
  }
}
