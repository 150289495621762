@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  gap: vars.$nodeSpacing;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  width: 100%;
  overflow-x: auto;
  grid-column: span 12;
}

.category {
  border-radius: vars.$borderRadiusM;
  padding: 0 vars.$nodeSpacing;
  height: 48px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: colors.$default-text;
  background: white;
  font-stretch: condensed;
  border-left: 3px solid colors.$primary-color;
}