@use '../../../styleguide/colors.scss';
@use '../../../styleguide/vars.scss';

.root {
  padding: 0 vars.$nodeSpacing;
  width: 100%;
  height: 136px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  position: relative;

  @media screen and (min-width: vars.$extra-large) {
    height: calc(100vh - 155px);
    background-color: rgba(0, 137, 255, 0.15);
  }
}

.title {
  font-size: 24xp;
  letter-spacing: 0.1px;
  color: var(--light-blue);
  font-stretch: condensed;
  font-weight: 700;
  display: none;

  @media screen and (min-width: vars.$extra-large) {
    display: block;
  }
}

.action {
  display: none;

  @media screen and (min-width: vars.$extra-large) {
    display: flex;
  }
}

.imageBackground {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  filter: blur(8px);

  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }
}

.imageSearch {
  height: 100%;
  width: auto;
  object-fit: contain;
  z-index: 1;

  @media screen and (min-width: vars.$extra-large) {
    width: 100%;
    height: auto;
    max-height: 50%;
  }
}
