@use '../../styleguide/vars.scss';
@use '../../styleguide/colors.scss';

.root {
  margin-bottom: vars.$gutter;
  @media screen and (min-width: vars.$medium) {
    display: none;
  }
}

.breadcrumb {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.breadcrumbItem {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  border-radius: 4px;
  border: 1px solid var(--light-blue);
  background-color: white;
  text-decoration: none;
  font-stretch: condensed;
}
