@use "vars";

@mixin rounded {
  border-radius: 4px;
}

@mixin roundedLarge {
  border-radius: 8px;
}

@mixin dropShadow {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03);
}

@mixin dropShadowCard {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}

@mixin dropShadowBold {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}

@mixin sectionTitle {
  font-stretch: condensed;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  @media screen and (min-width: vars.$medium) {
    font-size: 28px;
    line-height: 32 px;
  }
  @media screen and (min-width: vars.$extra-large) {
    font-size: 32px;
    line-height: 38px;
  }
}
