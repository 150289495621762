@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  column-gap: vars.$nodeSpacing;
  width: 100%;
  gap: 16px;

  @media screen and (min-width: vars.$medium) {
    flex-direction: row;
    align-items: center;
    width: initial;
  }
}
.root {
  display: flex;
  padding: 12px 0;
  gap: 16px;
  height: 48px;
  border-radius: 4px;
  color: var(--Fill-Blue-default, #3293E6);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  font-stretch: condensed;
  text-wrap: nowrap;
}

.hidden {
  display: none;

  @media screen and (min-width: vars.$medium) {
    display: flex;
  }
}
