@use '../../styleguide/vars.scss';
@use '../../styleguide/colors.scss';

@mixin layout {
  font-size: inherit;
  font-weight: inherit;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .breadcrumbItem {
    &:not(:last-child) {
      display: none;

      @media screen and (min-width: vars.$medium) {
        display: inline-block;
      }
    }

    &:last-child {
      font-stretch: condensed;
      font-weight: bold;
      width: max-content;
      display: inline-block;
    }
  }

  .mobileLi {
    &:last-child {
      display: none;

      @media screen and (min-width: vars.$medium) {
        display: inline-block;
      }
    }

    &:nth-last-child(2) {
      display: inline-block;
      font-stretch: condensed;
      font-weight: bold;

      @media screen and (min-width: vars.$medium) {
        font-weight: 400;
        font-stretch: normal;
      }
    }
  }

  a {
    color: colors.$default-text;
    font-weight: bold;
    height: 100%;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: colors.$primary-color;
    }
  }
}

.homeItem {
  display: none;
  @media screen and (min-width: vars.$medium) {
    display: inline-block;
  }
}

.mobileHomeItem {
  display: inline-block;
}

.root {
  display: flex;
  @include layout;

  @media screen and (min-width: vars.$extra-extra-large) {
    margin-top: 0;
  }
}

.homeIcon {
  margin-right: 8px;
  margin-bottom: -2px;
}

.liWithArrow {
  &::before {
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDcgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0wLjI1MDAwMSA1Ljk5OTZDMC4yNTAwMDEgNS43ODAyOSAwLjMzMjM5OSA1LjU2MDg3IDAuNDk3MTk0IDUuMzkzNzNMNS41NTk2OSAwLjI1MTEwNUM1Ljg4OTI4IC0wLjA4MzcwMTggNi40MjMyMiAtMC4wODM3MDE3IDYuNzUyODEgMC4yNTExMDVDNy4wODI0IDAuNTg1OTExIDcuMDgyNCAxLjEyODMgNi43NTI4MSAxLjQ2MzFMMi4yODU1NSA1Ljk5OTZMNi43NTIxNSAxMC41MzY5QzcuMDgxNzQgMTAuODcxNyA3LjA4MTc0IDExLjQxNDEgNi43NTIxNSAxMS43NDg5QzYuNDIyNTYgMTIuMDgzNyA1Ljg4ODYyIDEyLjA4MzcgNS41NTkwMyAxMS43NDg5TDAuNDk2NTM0IDYuNjA2MjdDMC4zMzE3MzkgNi40Mzg4NiAwLjI1MDAwMSA2LjIxOTIzIDAuMjUwMDAxIDUuOTk5NloiIGZpbGw9IiMyQTJBMkEiLz4KPC9zdmc+Cg==);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 8px;
    height: 8px;
    margin: auto 8px;
    display: inline-block;

    @media screen and (min-width: vars.$medium) {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNzUgNi4wMDA0QzkuNzUgNi4yMTk3MSA5LjY2NzYgNi40MzkxMyA5LjUwMjgxIDYuNjA2MjdMNC40NDAzMSAxMS43NDg5QzQuMTEwNzIgMTIuMDgzNyAzLjU3Njc4IDEyLjA4MzcgMy4yNDcxOSAxMS43NDg5QzIuOTE3NiAxMS40MTQxIDIuOTE3NiAxMC44NzE3IDMuMjQ3MTkgMTAuNTM2OUw3LjcxNDQ1IDYuMDAwNEwzLjI0Nzg1IDEuNDYzMUMyLjkxODI2IDEuMTI4MyAyLjkxODI2IDAuNTg1OTExIDMuMjQ3ODUgMC4yNTExMDVDMy41Nzc0NCAtMC4wODM3MDE2IDQuMTExMzggLTAuMDgzNzAxNiA0LjQ0MDk3IDAuMjUxMTA1TDkuNTAzNDcgNS4zOTM3M0M5LjY2ODI2IDUuNTYxMTQgOS43NSA1Ljc4MDc3IDkuNzUgNi4wMDA0WiIgZmlsbD0iIzJBMkEyQSIvPgo8L3N2Zz4K);
    }
  }
}
