$extra-small: 0px;
$small: 576px;
$medium: 768px;
$large: 984px;
$extra-large: 1200px;
$extra-extra-large: 1560px;

$gutterXS: 20px;
$gutterGeneric: 32px;
$gutter: var(--gutter);
$nodeSpacing: var(--node-spacing);
$spacingL: 16px;

$borderRadiusS: 2px;
$borderRadiusM: 4px;